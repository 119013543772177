import React from "react";
import { Container, Link } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, SectionHeading } from "../../../Styles/style";
import backgroundImage from "../../../../assets/images/aboutBg.png";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 !important;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  display: grid;
  place-items: center;
  padding: 20px 50px !important;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;

const AboutSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="about">
        <InnerContinerWraper>
          <CustomGrid container jc="space-between">
            <CustomGrid container item xs={12} g="20px">
              <CustomGrid item xs={12}>
                <SectionHeading data-aos="zoom-in">ABOUT</SectionHeading>
              </CustomGrid>
              <CustomGrid
                data-aos="fade-right"
                data-aos-duration="1000"
                item
                xs={12}
                sm={10}
              >
                <Paragraph fs="28px" fs599="22px" p="10px 0">
                  Welcome to the intriguing realm of Baby Wagyu Token, because
                  evidently, the crypto world demanded a new player in the game.
                  Spawned from the illustrious $WAGYU on the Ethereum chain,
                  Baby Wagyu is here to make its mark, proving that even in the
                  realm of blockchain, we can't resist a touch of, shall we say,
                  uniqueness? This isn't just another token; it's a community
                  where bulls and bears clash in a perfect storm of financial
                  enlightenment. Brace yourselves for a bullish journey that may
                  leave you questioning the conventional path. Invest wisely, or
                  don't – we're just down for anything degen really.
                </Paragraph>
              </CustomGrid>
              <CustomGrid xs={12}>
                <Link href="https://t.me/babywagyu_eth" target="blank">
                  <CustomButton
                    bgc="linear-gradient(to right, #BC2638,#F82C41)"
                    c="#FCD5A5"
                    hbgc="linear-gradient(to right, #BC2638,#F82C41)"
                    hc="#FCD5A5"
                  >
                    Join Us
                  </CustomButton>
                </Link>
              </CustomGrid>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default AboutSection;
