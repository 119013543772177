import styled from "@emotion/styled";
import { Container, Grid, Link } from "@mui/material";
import React from "react";
import backgroundImage from "../../../../assets/images/comunity.png";
import { CustomGrid, DataImage } from "../../../Styles/style";
import heading from "../../../../assets/images/comunityHeading.png";

import tg from "../../../../assets/images/tg.png";
import x from "../../../../assets/images/x.png";
const ContinerWraper = styled(Container)`
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  z-index: 0;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0 !important;
  ::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(30, 18, 40, 0.8),
      rgba(30, 18, 40, 0.9)
    );
    z-index: -1;
  }
  @media (max-width: 599px) {
    min-height: auto;
  }
`;


const Community = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="community">
      <Container>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            rowGap: "20px",
            zIndex: "1",
            columnGap: "30px",
          }}
        >
          <CustomGrid item xs={12}>
            <DataImage mw="50%" mw599="100%" data-aos="zoom-in" src={heading} />
          </CustomGrid>
          <CustomGrid item xs={12}>
            <div style={{ display: "flex", gap: "10px" }}>
              <Link sx={{ cursor: "pointer" }} href="https://t.me/babywagyu_eth" target="blank">
                <DataImage mw="70px" src={tg} />
              </Link>
              <Link sx={{ cursor: "pointer" }} href="https://twitter.com/babywagyu_eth" target="blank">
                <DataImage mw="70px" src={x} />
              </Link>
            </div>
          </CustomGrid>
        </Grid>
      </Container>
    </ContinerWraper>
  );
};

export default Community;
