import React, { useState } from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage, SectionHeading } from "../../../Styles/style";
import backgroundImage from "../../../../assets/images/tokenBg.png";
import miniwagy from "../../../../assets/images/miniwagy.png";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Paragraph from "../../Paragraph";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 !important;
  position: relative;
  z-index: 0;
  ::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(30, 18, 40, 0.8),
      rgba(30, 18, 40, 0.9)
    );
    z-index: -1;
  }
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  padding: 0 50px !important;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 0 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;

const Tokenomics = () => {
  const [showToast, setShowToast] = useState(false);
  const addressToCopy = "00000000000000Xx0000x0000xxxx0";

  const handleCopy = () => {
    navigator.clipboard.writeText(addressToCopy);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 2000);
  };
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="tokenomics">
        <InnerContinerWraper>
          <CustomGrid
            container
            p="30px 0"
            fd="column"
            jc="space-around"
            sx={{ minHeight: "calc(100vh - 67px)" }}
          >
            <CustomGrid item xs={12} fd="column" g="40px 0">
              <SectionHeading c="#fff" data-aos="zoom-in">BABYNOMICS</SectionHeading>
              <Paragraph
                color="#fff"
                data-aos="zoom-in"
                fs="42px"
                fs599="32px"
                p="10px 0"
              >
                Total Supply: 500,000,000
              </Paragraph>
              <CustomGrid
                container
                item
                xs={12}
                sm={8}
                md={6}
                jc="space-between"
              >
                <CustomGrid
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  item
                  xs={6}
                  sm={4}
                  fd="column"
                  ai="center"
                >
                  <DataImage mw="50%" src={miniwagy} />
                  <Paragraph color="#fff" fs="26px" fs599="26px">
                    0% Buy Tax
                  </Paragraph>
                </CustomGrid>
                <CustomGrid
                  data-aos="fade-up"
                  data-aos-duration="1300"
                  item
                  xs={6}
                  sm={4}
                  fd="column"
                  ai="center"
                >
                  <DataImage mw="50%" src={miniwagy} />
                  <Paragraph color="#fff" fs="26px" fs599="26px">
                    O% Sell Tax
                  </Paragraph>
                </CustomGrid>
              </CustomGrid>
              <CustomGrid
                data-aos="fade-up"
                data-aos-duration="2800"
                container
                item
                xs={12}
                sm={10}
                md={6}
                p="10px 20px"
                ai="start"
                jc="space-between"
                sx={{
                  background: "#FCD5A5",
                  boxShadow: "0px 5px 10px #000",
                  borderRadius: "10px",
                  position: "relative",
                }}
              >
                {showToast && (
                  <div
                    style={{
                      width: "300px",
                      height: "40px",
                      borderRadius: "10px",
                      position: "absolute",
                      top: "-12%",
                      left: "50%",
                      transform: "translate(-50%,-12%)",
                      display: "grid",
                      placeItems: "center",
                      background: "#000",
                      color: "#FCD5A5",
                      zIndex: "1",
                    }}
                  >
                    Contract Address Copied
                  </div>
                )}
                <Paragraph fs="18px" fs599="16px" color="#000">
                  Contract Address:
                </Paragraph>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap:"5px"
                  }}
                >
                  <Paragraph fs="18px" fs599="16px" color="#000">
                    TBA
                  </Paragraph>
                  <FileCopyIcon sx={{cursor:"pointer"}} onClick={handleCopy}/>
                </div>
              </CustomGrid>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default Tokenomics;
