import styled from "@emotion/styled";
import { Container, Grid, Link } from "@mui/material";
import React from "react";
import image1 from "../../../../assets/images/logo.png";
import Paragraph from "../../Paragraph";
import {
  DataImage,
  CustomGrid,
} from "../../../Styles/style";
const ContinerWraper = styled(Container)`
  background: #1e1228;
  position: relative;
  height: auto;
  width: 100%;
  padding: 40px 0 0 !important;
`;


const Footer = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl">
        <Container>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              rowGap: "20px",
              zIndex: "1",
              columnGap: "30px",
              padding: "0 0 20px",
            }}
          >
            <CustomGrid
              item
              xs={12}
              md={10}
              jc="space-evenly"
              p="0 0 20px"
              sx={{ borderBottom: "1px solid #FCD5A5" }}
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <Link sx={{ textDecoration: "none" }} href="#about">
                <Paragraph fs599="12px" ta="left" color="#fff">
                  About
                </Paragraph>
              </Link>
              <Link sx={{ textDecoration: "none" }} href="#Babynomics">
                <Paragraph fs599="12px" ta="left" color="#fff">
                  Babynomics
                </Paragraph>
              </Link>
              <DataImage mw="100px" mw599="50px" src={image1} />
              <Link sx={{ textDecoration: "none" }} href="#roadmap">
                <Paragraph fs599="12px" ta="left" color="#fff">
                  Roadmap
                </Paragraph>
              </Link>
              <Link sx={{ textDecoration: "none" }} href="#community">
                <Paragraph fs599="12px" ta="left" color="#fff">
                  Community
                </Paragraph>
              </Link>
            </CustomGrid>
            <CustomGrid item xs={12}>
              <Paragraph color="#fff">© 2023 Babywagyu Token.</Paragraph>
            </CustomGrid>
          </Grid>
        </Container>
      </ContinerWraper>
    </>
  );
};

export default Footer;
