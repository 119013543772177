import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid,  SectionHeading } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  padding: 0px 0 !important;
  position: relative;
  z-index: 0;
  background:linear-gradient(to right, #BC2638,#F82C41) ;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  padding: 0 50px !important;
  display: grid;
  place-items: center;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 0 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;

const RoadMap = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="roadmap">
        <InnerContinerWraper>
          <CustomGrid
            container
            p="30px 0"
            fd="column"
            jc="space-around"
          >
            <CustomGrid item xs={12} fd="column" g="20px 0">
              <SectionHeading data-aos="zoom-in" c="#fff">Roadmap</SectionHeading>
              <CustomGrid container item xs={12} g="20px">
                <CustomGrid
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  item
                  xs={12}
                  md={3.5}
                  jc="start"
                  ai="start"
                  fd="column"
                  g="10px"
                  sx={{
                    background: "#FCD5A5",
                    minHeight:"300px",
                    borderRadius: "5px",

                    boxShadow: "0px 5px 10px #000",
                  }}
                >
                  <CustomGrid
                    item
                    xs={12}
                    fd="column"
                    g="10px"
                    jc="start"
                    ai="start"
                    p="20px"
                  >
                    <Paragraph ta="left" fs="18px" fs599="16px" fm="Gothic">
                      PHASE 1
                    </Paragraph>
                    <Paragraph ta="left">
                      🥩 Website Launch <br />
                      🥩 Token Launch <br />
                      - 1,000 holders <br />
                      - CG and CMC Listings <br />- $BABYWAGYU Trending on
                      Twitter
                    </Paragraph>
                  </CustomGrid>
                </CustomGrid>
                <CustomGrid
                  data-aos="fade-up"
                  data-aos-duration="1300"
                  item
                  xs={12}
                  md={3.5}
                  jc="start"
                  ai="start"
                  fd="column"
                  g="10px"
                  sx={{
                    background: "#FCD5A5",
                    minHeight:"300px",
                    borderRadius: "5px",

                    boxShadow: "0px 5px 10px #000",
                  }}
                >
                  <CustomGrid
                    item
                    xs={12}
                    fd="column"
                    g="10px"
                    jc="start"
                    ai="start"
                    p="20px"
                  >
                    <Paragraph ta="left" fs="18px" fs599="16px" fm="Gothic">
                      PHASE 2
                    </Paragraph>
                    <Paragraph ta="left">
                      - Community Partnerships <br />
                      - Exchange Listings
                      <br />
                      - Release Official Partnerships
                      <br />
                      - Further Digital Marketing
                      <br />
                      - 5000 Holders
                      <br />
                    </Paragraph>
                  </CustomGrid>
                </CustomGrid>
                <CustomGrid
                  data-aos="fade-up"
                  data-aos-duration="1600"
                  item
                  xs={12}
                  md={3.5}
                  jc="start"
                  ai="start"
                  fd="column"
                  g="10px"
                  sx={{
                    background: "#FCD5A5",
                    minHeight:"300px",
                    borderRadius: "5px",

                    boxShadow: "0px 5px 10px #000",
                  }}
                >
                  <CustomGrid
                    item
                    xs={12}
                    fd="column"
                    g="10px"
                    jc="start"
                    ai="start"
                    p="20px"
                  >
                    <Paragraph ta="left" fs="18px" fs599="16px" fm="Gothic">
                      PHASE 3
                    </Paragraph>
                    <Paragraph ta="left">
                      - International Communities
                      <br />
                      - Website Revisions
                      <br />
                      - Meta Takeover
                      <br />
                      - 10,000 Holders
                      <br />
                    </Paragraph>
                  </CustomGrid>
                </CustomGrid>
              </CustomGrid>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default RoadMap;
