import React from "react";
import { Container, Grid, Link } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage } from "../../../Styles/style";
import backgroundImage from "../../../../assets/images/backgroundImage.png";
import heroHeading from "../../../../assets/images/heroHeading.png";
import etherscan from "../../../../assets/images/etherscan.png";
import dex from "../../../../assets/images/dex.png";
import uniswap from "../../../../assets/images/uniswap.png";
import tg from "../../../../assets/images/tg.png";
import x from "../../../../assets/images/x.png";
import LockerMenu from "../../LockerMenu";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100svh;
  width: 100%;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 !important;
  position: relative;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100svh - 70px);
  width: 100%;
  padding: 0 50px !important;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 0 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;

const HeroSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="home">
        <LockerMenu />
        <InnerContinerWraper>
          <CustomGrid container p="40px 0" fd="column" ai="start" jc="start">
            <CustomGrid
              container
              item
              xs={12}
              sm={9}
              md={7}
              ai="start"
              fd="column"
              g="20px"
            >
              <DataImage
                data-aos="zoom-in"
                mw="80%"
                mw899="100%"
                src={heroHeading}
              />
              <Paragraph
                sx={{ textShadow: "0px 5px 5px #000" }}
                color="#FCD5A5"
                fs="28px"
                fs599="22px"
                p="10px 0"
                ta="left"
                data-aos="zoom-in"
              >
                Disclaimer: You may become hungry.
              </Paragraph>
              <Grid
                data-aos="fade-up"
                sx={{
                  display: "flex",
                  flexDirection:{xs:"column",sm:"row"},
                  gap: "10px",
                }}
              >
                <CustomButton
                  bgc="linear-gradient(to right, #BC2638,#F82C41)"
                  c="#FCD5A5"
                  hbgc="linear-gradient(to right, #BC2638,#F82C41)"
                  hc="#FCD5A5"
                >
                  Buy Now
                </CustomButton>
                <CustomButton>View Chart</CustomButton>
                <CustomButton>LP lock</CustomButton>
              </Grid>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
      <Container maxWidth="xxl"  sx={{ background: "#F1BC7B" }}>
        <Container>
          <CustomGrid p="50px 0" data-aos="fade-up" container item xs={12}>
            <CustomGrid item xs={12}>
              <div style={{ display: "flex", gap: "10px" }}>
                <Link  href="" target="blank">
                  <DataImage mw="70px" src={etherscan} />
                </Link>
                <Link  href="" target="blank">
                  <DataImage mw="70px" src={dex} />
                </Link>
                <Link  href="" target="blank">
                  <DataImage mw="70px" src={uniswap} />
                </Link>
                <Link  href="https://t.me/babywagyu_eth" target="blank">
                  <DataImage mw="70px" src={tg} />
                </Link>
                <Link  href="https://twitter.com/babywagyu_eth" target="blank">
                  <DataImage mw="70px" src={x} />
                </Link>
              </div>
            </CustomGrid>
          </CustomGrid>
        </Container>
      </Container>
    </>
  );
};

export default HeroSection;
